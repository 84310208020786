import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import Form from '../modules/Form';

const PageTemplate = (props) => {
  const post = props.data.post;
  const { location } = props;
  const microSitename = props.pageContext.categories
    ? props.pageContext.categories.nodes[0].name
    : '';
  useEffect(() => {
    document.body.classList.remove('header-sticky');
  }, []);
  return (
    <>
      <Form
        props={props}
        location={location}
        post={post}
        microSitename={microSitename}
        modules={post.flexibleContent}
      />
    </>
  );
};

export default PageTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query FormById($id: String!) {
    post: wpProperty(id: { eq: $id }) {
      id
      title
      uri
      ...ModuleFormFragmentFragment
    }
  }
`;
