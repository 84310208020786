import { graphql } from 'gatsby';
import React from 'react';
import Form from '../components/Form';

const ModuleForm = (props) => {
  const modules = props.modules.flexibleContent;
  const post = props.post;
  const { location } = props;
  const microSitename = props.microSitename;
  const components = {
    Form,
  };

  return (
    <>
      {modules
        ? modules.map((module, i) => {
            const moduleName = module.__typename.replace(
              'WpProperty_Flexiblecontent_FlexibleContent_',
              ''
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                post,
                module,
                location,
                i,
                modules: modules,
                microSitename: microSitename,
              })
            );
          })
        : null}
    </>
  );
};

export default ModuleForm;

export const ModuleFormFragmentFragment = graphql`
  fragment ModuleFormFragmentFragment on WpProperty {
    flexibleContent {
      flexibleContent {
        __typename
        ...FormFragment
      }
    }
  }
`;
